.balances {
  max-height: 100%;
  /* height: 100%; */
  width: 100%;

  /* gap: 16px; */

  display: flex;
  flex-direction: column;
  /* align-items: stretch; */

  transition: max-height 0.4s ease;

  /* overflow: hidden */
  /* display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  gap: 16px;
  transition: max-height 0.5s ease;
  overflow: hidden; */
}

.balances.closed {
  max-height: 50px;
  overflow: hidden;
  transition: max-height 0.4s ease;
}
.balances.closed .balances-list {
  /* display: none; */
}

.balances-title {
  display: flex;
  padding: 12px 0;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  color: var(--tech-black, #031022);

  font-family: 'Mulish';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.4px;
}

.balances-title-arrow {
  width: 24px;
  height: 24px;
}

.balances-title-arrow.expanded {
  transform: rotate(180deg);
  transition: 0.5s ease;
}

.balances-title-arrow.closed {
  transform: rotate(0deg);
  transition: 0.5s ease;
}

.balances-title-arrow:hover {
  cursor: pointer;
}

.balances-list {
  display: flex;
  /* align-items: flex-start; */
  flex-direction: column;
  width: 100%;
  gap: 16px;
  /* min-height: 100%; */

  /* overflow: hidden; */
}

.balances-list.closed {
  display: flex;

  max-height: 0px;
}
