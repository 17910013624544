.corporation-form {
    width: 100%;
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    border-radius: 16px;
    background: var(--Tech-White, #FFF);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.corporation-form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.corporation-form-wrapper .title {
    display: flex;
    padding: 16px 24px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    border-bottom: 1px solid var(--Tech-Grey-Border, #F3F4F6);
    color: var(--Tech-Black, #031022);

    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; 
    letter-spacing: -0.4px;
}

.corporation-form-wrapper .input-list {
    display: flex;
    flex-direction: column;
    padding: 0px 24px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}