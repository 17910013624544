.exchange-page-order {
  /* width: 1200px; */
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.exchange-page-order-content {
  width: 100%;

  display: flex;
  /* flex-direction: row; */
  /* align-items: stretch; */
  gap: 32px;
}

.exchange-page-order-content-body {
  display: flex;
  width: 100%;
  min-height: 100%;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 20px;
  background: var(--tech-white, #fff);

  /* Frame */
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.exchange-page-content-body-text {
  width: calc(100% - 48px);
}

.exchange-page-content-order-body-text {
  width: calc(100% - 48px);
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 0px 24px 24px 24px;
  gap: 16px;

  color: var(--tech-grey-hard, #647081);
  /* Caption/C1 */
  font-family: 'Mulish';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.24px;
}

.exchange-page-order-content-button {
  width: 190px;
}

.exchange-page-content-body-text p {
  margin: 0;
  text-align: right;
}

@media (max-width: 945px) {
  .exchange-page-order {
    gap: 32px;
  }
  .exchange-page-order-content {
    flex-direction: column;
    gap: 24px;
  }
  .exchange-page-order {
    gap: 32px;
  }
  .exchange-page-order-content-button {
    width: 100%;
  }
  .exchange-page-order-content-body-text span {
    max-width: 180px;
  }
}
