.mockup {
  width: 550px;
  height: 727px;

  border-radius: 52px;
  background: var(--primary-primary, #408ef6);

  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}

@media (max-width: 1100px) {
  .mockup {
    display: none;
  }
}

.mockup-logo {
  position: relative;

  top: 141px;

  width: 336px;
  /* height: 680px; */
  flex-shrink: 0;
}

.location-point {
  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 5px;
}

.location-point span {
  display: inline-flex;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 10px;

  color: #292d32;
  text-align: center;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px #617fbf;
}
