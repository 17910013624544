.history-page {
  /* height: 100%; */
  width: calc(100% - 48px);
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px 24px 24px;
  gap: 24px;
}

.history-page-content {
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
}

.history-page-content-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.history-page-content-hello {
  color: var(--tech-grey-medium, #a8b1be);

  font-family: "Mulish";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.28px;
}

.history-page-content-head {
  color: var(--tech-black, #031022);

  font-family: "Mulish";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.64px;
}

.history-page-content-body {
  display: flex;
  width: 100%;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 20px;
  background: var(--tech-white, #fff);

  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.history-page-content-body-title {
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  border-bottom: 1px solid var(--tech-grey-border, #f3f4f6);
}

.history-page-content-body-title span {
  color: var(--tech-black, #031022);

  font-family: "Mulish";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.4px;
}

.history-page-content-body-text {
  display: flex;
  padding: 0px 24px 24px 24px;
  align-items: center;
  gap: 32px;
  align-self: stretch;

  color: var(--tech-black, #031022);

  font-family: "Mulish";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.history-page-content-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
}

.history-page-content-body-qr {
  width: 153px;
  height: 153px;
  background-color: grey;
}

@media (max-width: 430px) {
  .history-page-content-body-qr {
    display: none;
  }
  .history-page-list {
    height: 100%;

    /* max-width: 100%; */
  }
  .history-page-content {
    /* max-width: 100%; */
    /* min-width: unset; */
    padding-bottom: 48px;
    width: calc(100% - 96px);
    /* height: calc(100% - 95px); */
  }
  .history-page-content-button {
    padding: 12px 0;
  }

  .history-page-content-button .large-button {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
