.dropdown {
    width: 100%;
}

.dropdown-wrapper {
    display: flex;
    padding: 12px 0 ;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.dropdown-wrapper .text-wrapper {
    display: flex;
    padding: 0px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}


.text-wrapper span {
    flex: 1 0 0;

    color: var(--Tech-Black, #031022);

    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: -0.28px;
}

.dropdown-wrapper .upload-wrapper {
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    border-radius: 16px;
    border: 1px dashed var(--Tech-Grey-Medium, #A8B1BE);

    /* box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08); */
}

.upload-wrapper input {
    display: none;
}

.upload-wrapper .text-wrapper-2 {
    align-self: stretch;
    text-align: center;

    color: var(--Tech-Black, #031022);

    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;
}

.upload-wrapper .upload-text {
    cursor: pointer;
    color: var(--Primary-Primary, #408EF6);
    text-align: center;
    
    /* Button/Bt2 */
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
}

.dropdown-wrapper .files {
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
}
