.header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.header-body {
  display: flex;
  flex-direction: row;
  width: calc(100% - 48px);
  height: calc(80px - 56px);
  padding: 32px 24px 24px;
  justify-content: space-between;
  align-items: center;
  background: var(--white, #ffffff);
}

.header-content-questions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.header-content-profile {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header-content-bell-icon {
  width: 24px;
  height: 24px;
}

.header-content-questions span {
  color: var(--primary-primary, #408ef6);
  text-align: center;

  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.header-content-questions .header-content-bell-icon path {
  fill: var(--primary-primary, #408ef6);
}

/* .header-content-questions:hover span {
  color: var(---tech-grey-medium, #a8b1be);
}

.header-content-questions:hover .header-content-bell-icon path {
  fill: var(---tech-grey-medium, #a8b1be);
} */

.header-content-profile span {
  color: #000;

  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.header-toogle {
  cursor: pointer;
}

.header-content-questions:hover {
  cursor: pointer;
}

.header-content-bell-icon:hover {
  cursor: pointer;
}

.header-toogle {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.header-toogle-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 32px;
}

@media (max-width: 820px) {
  .header {
    display: none;
  }
}

.header-content-bell-icon:hover path {
  fill: var(--primary-primary, #408ef6);
}

.header-content-bell-icon:hover circle {
  fill: var(--primary-primary, #408ef6);
}

.header-content-profile-arrow.expanded {
  transform: rotate(180deg);
  transition: 0.5s ease;
}

.header-content-profile-arrow.closed {
  transform: rotate(0);
  transition: 0.5s ease;
}

.header-content-profile:hover {
  cursor: pointer;
}

.header-content-profile .header-content-bell-icon path {
  fill: var(--primary-primary, #408ef6);
}

.header-content-profile .header-content-bell-icon circle {
  fill: var(--primary-primary, #408ef6);
}

/* .header-content-profile:hover .header-content-bell-icon path {
  fill: var(---tech-grey-medium, #a8b1be);
}

.header-content-profile:hover .header-content-bell-icon circle {
  fill: var(---tech-grey-medium, #a8b1be);
} */

.header-content-profile-popup {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  z-index: 10000;
}

.header-content-profile-popup span {
  color: var(--Black, #26292d);

  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;
}

.header-content-profile-popup:hover {
  cursor: pointer;
}

.header-content-profile-popup:hover span {
  cursor: pointer;
}

.header-content-profile-popup:hover path {
  fill: var(--primary-primary, #408ef6);
}

.header-content-profile-popup:hover circle {
  fill: var(--primary-primary, #408ef6);
}
