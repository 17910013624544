.recovery-page {
  display: flex;
  width: 100%;
  padding: 49px 0 267px 0;
  flex-direction: column;
  align-items: center;
  gap: 107px;
}

.recovery-page-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recovery-page-header .need-acc {
  display: flex;
  align-items: center;
}

.recovery-page-header .logo {
  width: 187px;
  height: 30px;
}

.mobile-need-acc {
  display: none;
}

@media (max-width: 820px) {
  .recovery-page {
    width: calc(100% - 44px);
    padding: 32px 22px;
    margin: 0 auto;
  }
  .recovery-page-header .need-acc {
    display: none;
  }

  .mobile-need-acc {
    display: flex;
    align-items: center;
  }
}

.recovery-page-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.recovery-page-form-title {
  color: var(--tech-black, #031022);

  font-family: 'Mulish';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.64px;
}

.recovery-page-form-subtext {
  color: var(--tech-black, #031022);
  text-align: center;

  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
}
