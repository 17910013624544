@media (max-width: 820px) {
  .home-appbar {
    /* height: 80px; */
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--white, #ffffff);

    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .appbar-content-icon {
    width: 24px;
    height: 24px;
  }

  .home-appbar-toogle {
    cursor: pointer;
  }

  .appbar-content-icon path {
    fill: var(--primary-primary, #408ef6);
  }

  .appbar-content-icon circle {
    fill: var(--primary-primary, #408ef6);
  }

  .appbar-content-icon:hover {
    cursor: pointer;
  }

  .home-appbar-toogle {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  .home-appbar-toogle-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  .appbar-content {
    display: flex;
    align-items: center;
    gap: 18px;
  }
}

@media (min-width: 821px) {
  .home-appbar {
    display: none;
  }
}

.appbar-content-icon:hover path {
  fill: var(--primary-primary, #408ef6);
}

.appbar-content-icon:hover circle {
  fill: var(--primary-primary, #408ef6);
}
