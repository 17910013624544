.home-page {
  height: 100%;
  display: inline-flex;
  transition: height 0.3s ease;
  /* padding-top: calc(80px + 32px); */
}

.home-page-elements {
  height: 100%;

  display: flex;
  /* height: 100%; */
  flex-direction: column;
  /* overflow: auto; */
  transition: height 0.3s ease;
}
