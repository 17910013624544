.add-corporations-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding-bottom: 24px;
}

@media (max-width: 830px) {
    .add-corporations-form {
        padding-bottom: 98px
    }
}