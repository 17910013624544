.checkbox {
  width: 20px;
  height: 20px;
}

.checkbox input {
  margin: 0;
  padding: 0;

  width: 20px;
  height: 20px;
}
