.file-button {
    display: flex;
    align-items: center;
    gap: 24px;
    cursor: pointer;
}

.file-button .small-text-button span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}