.App {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

@media (max-width: 820px) {
  .App {
    justify-content: start;
  }
}

.wrapper {
  width: 1200px;
  height: 100%;
  display: inline-flex;
}

.wrapper-scroll {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.popup-content {
  position: relative;
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  border: 1px solid var(--tech-border, #f3f4f6);
  background: var(--White, #fefdfd);

  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
}

/* .popup-arrow {
} */

.popup-content.transfer-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.skeleton {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.skeleton span {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
