.send-type-button {
  position: relative;
  display: flex;
  min-height: calc(90px - 24px);
  /* /* min-width: calc(261px - 24px); */
  /* min-width: calc(261px - 24px);
   */
  min-width: calc(33.33% - 12px - 36px);

  width: calc(33.33% - 12px - 36px);
  /* max-width: calc(33% - 44px); */
  padding: 12px 16px;
  align-items: flex-start;
  gap: 12px;
  /* flex: 1 0 0; */

  border-radius: 16px;
  background: var(--tech-white, #fff);

  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  transition: transform 0.8s ease;
}
@media (max-width: 430px) {
  .send-type-button {
    display: flex;
    padding: 12px;
    min-height: 150px;
    min-width: calc(50% - 12px - 36px);
    width: calc(50% - 12px - 36px);
    /* max-width: 50%; */
    gap: 12px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 16px;
    background: var(--tech-white, #fff);

    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  }
}

@media (max-width: 820px) {
  .send-type-button {
    display: flex;
    padding: 12px;
    min-height: 150px;
    min-width: calc(50% - 12px - 36px);
    width: calc(50% - 12px - 36px);
    /* max-width: 50%; */
    gap: 12px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 16px;
    background: var(--tech-white, #fff);

    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  }
}

.send-type-button-logo {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;
  flex-shrink: 0;
}

.send-type-button-logo svg {
  width: 30px;
  height: 30px;
  flex-shrink: 0;

  fill: var(--tech-white, #fff);
}

.send-type-button-title {
  color: var(--tech-black, #031022);

  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.send-type-button-subtitle {
  color: var(--tech-grey-hard, #647081);

  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.send-type-button-hover {
  cursor: pointer;
  transform: translateY(-10px);
  transition: transform 0.5s ease;
}

.send-type-button:active {
  cursor: pointer;
  transform: translateY(-10px);
  transition: transform 0.5s ease;
}
