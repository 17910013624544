.transfer-tabs {
  position: relative;
  border-bottom: 1px solid var(--tech-grey-border, #f3f4f6);
}

.transfer-tabs-nav {
  display: flex;
  align-items: flex-end;
  gap: 32px;
  margin: 0;
  padding: 0px 24px;
}

.transfer-menu {
  display: none;
}

@media (max-width: 820px) {
  .transfer-tabs {
    border-bottom: none;
  }
  .transfer-tabs-nav {
    display: none;
  }

  .transfer-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 0 24px;
  }

  .transfer-menu span {
    color: var(--primary-primary, #408ef6);

    font-family: 'Mulish';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.32px;
  }

  .transfer-menu-list {
    display: flex;
    width: 220px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .transfer-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }

  .transfer-menu-item span {
    color: var(--tech-grey-medium, #a8b1be);

    font-family: 'Mulish';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.32px;
  }

  .transfer-menu-item.selected span {
    color: var(--primary-primary, #408ef6);

    font-family: 'Mulish';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.32px;
  }
}
