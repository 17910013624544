.input {
  width: 100%;
  padding: 8px 0;
}

.input-body {
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid var(--tech-grey-light, #e4e7eb);
}

.input-body.open {
  border-bottom: 1px solid var(--primary-primary, #408ef6);
}

.input-body:hover {
  cursor: pointer;
}

.input-body.disabled {
  border-bottom: 1px solid var(--tech-grey-light, #e4e7eb);
  background: var(--tech-grey-border, #f3f4f6);
}

.input-body.reject {
  border-bottom: 1px solid var(--primary-red, #e40038);
}

.input-menu {
  width: 100%;
  overflow: auto;
  max-height: 200px;
}

.input-menu-item {
  width: 100%;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.input-menu-item span {
  color: var(--Black, #26292d);

  /* Body/B2 */
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
}

.input-menu-item:hover {
  cursor: pointer;
  background: var(--tech-border, #f3f4f6);
}
.input-value {
  width: 100%;
  padding: 16px 8px 4px;
  height: 34px;
  color: #0a0a0a;

  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;

  border: 0;
  display: flex;
  align-items: center;
}

.input-icon {
  padding: 0 8px;
  display: flex;
  align-items: center;
}
.input-value:disabled {
}

.input-value:focus {
  outline: none;
}

.input-body:focus-within {
  border-bottom: 1px solid var(--primary-primary, #408ef6);
  background: var(--White, #fff);
}

.input-body label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 16px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  left: 8px;

  color: var(--tech-grey-medium, #a8b1be);

  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.input-body:focus-within label {
  transform: translate(0, 0px) scale(1);
}

.input-body .filled {
  transform: translate(0, 0px) scale(1);
}

.input-error {
  color: var(--tech-grey-hard, #647081);

  font-family: 'Mulish';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.24px;
}

.input-body-eye {
}

.input-body-eye:hover {
  cursor: pointer;
}

.input-badge {
  padding: 0 8px;
}

