.frame {
    width: 100%;
}

.frame .frame-wrapper {    
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 16px 24px;
    border-radius: 16px;
    background: var(--Tech-White, #FFF);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.frame-wrapper .div {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    position: relative;
}

.frame-wrapper .text-wrapper {
    margin-top: -1px;
    /* white-space: nowrap; */
    width: fit-content;
    color: var(--Tech-Black, #031022);
    font-family: 'Mulish';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.32px;
}

.frame-wrapper .div-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
    width: 100%;
}

.frame-wrapper .group {
    width: 100%;
    height: 11px;
    border-radius: 30px;
    background: var(--Tech-Grey-Border, #F3F4F6);    
}

.frame-wrapper .rectangle {
    height: 11px;
    flex-shrink: 0;
    border-radius: 30px;
    background: var(--Green-Medium, #A4E9B3);

    transition: width 1s ease-out;
}

.frame-wrapper .div-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
}

.frame-wrapper .text-wrapper-2 {
    width: 35px;
    color: var(--Tech-Black, #031022);
    text-align: right;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; 
    letter-spacing: -0.32px;
}
