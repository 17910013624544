.home-menu-list.open {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;
}

.home-menu-list {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 18px;
}
