.submenu-list {
  display: block;

  max-height: 160px;
  padding: 0 0 0 64px;
  transition: max-height 0.5s ease;
  overflow: hidden;
}

.submenu-list.closed {
  display: block;

  max-height: 0px;
  padding: 0 0 0 64px;
}

.submenu-list-content {
  display: block;
}


.submenu-list-item {
  display: flex;
  padding: 8px 8px;
  align-items: center;
  justify-content: start;

  gap: 10px;
  align-self: stretch;
}

.popup-content .submenu-list-content {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.popup-content .submenu-list-item {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.submenu-list-item span {
  color: var(--tech-grey-hard, #647081);

  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;

  white-space: nowrap;
}

.submenu-list-item span:hover {
  color: var(--primary-primary, #408ef6);

  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;

  cursor: pointer;
}

.submenu-list-item.selected span {
  color: var(--primary-primary, #408ef6);
}
