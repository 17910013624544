html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'sans-serif Neue', 'Mulish',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

::-webkit-scrollbar {
  display: none;
  /* width: 5px; */
}

::-webkit-scrollbar-track {
  margin: 0;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}

span {
  font-family: 'Mulish', sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}

:root {
  --body-b1-font-family: "Mulish", sans-serif;
  --body-b1-font-size: 16px;
  --body-b1-font-style: normal;
  --body-b1-font-weight: 400;
  --body-b1-letter-spacing: -0.32px;
  --body-b1-line-height: 22px;
  --body-b2-font-family: "Mulish", sans-serif;
  --body-b2-font-size: 14px;
  --body-b2-font-style: normal;
  --body-b2-font-weight: 400;
  --body-b2-letter-spacing: -0.28px;
  --body-b2-line-height: 20px;
  --button-bt1-font-family: "Mulish", sans-serif;
  --button-bt1-font-size: 16px;
  --button-bt1-font-style: normal;
  --button-bt1-font-weight: 700;
  --button-bt1-letter-spacing: -0.32px;
  --button-bt1-line-height: 22px;
  --button-bt2-font-family: "Mulish", sans-serif;
  --button-bt2-font-size: 14px;
  --button-bt2-font-style: normal;
  --button-bt2-font-weight: 700;
  --button-bt2-letter-spacing: -0.28px;
  --button-bt2-line-height: 18px;
  --caption-c1-bold-font-family: "Mulish", sans-serif;
  --caption-c1-bold-font-size: 12px;
  --caption-c1-bold-font-style: normal;
  --caption-c1-bold-font-weight: 700;
  --caption-c1-bold-letter-spacing: -0.24px;
  --caption-c1-bold-line-height: 16px;
  --caption-c1-font-family: "Mulish", sans-serif;
  --caption-c1-font-size: 12px;
  --caption-c1-font-style: normal;
  --caption-c1-font-weight: 400;
  --caption-c1-letter-spacing: -0.24px;
  --caption-c1-line-height: 16px;
  --drop-button: 0px 5px 10px 0px rgba(40, 53, 193, 0.4);
  --frame: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  --greenhard: rgba(52, 180, 80, 1);
  --greenlight: rgba(222, 247, 227, 1);
  --greenmedium: rgba(164, 233, 179, 1);
  --headline-h1-font-family: "Mulish", sans-serif;
  --headline-h1-font-size: 40px;
  --headline-h1-font-style: normal;
  --headline-h1-font-weight: 700;
  --headline-h1-letter-spacing: -0.8px;
  --headline-h1-line-height: 50px;
  --headline-h2-font-family: "Mulish", sans-serif;
  --headline-h2-font-size: 32px;
  --headline-h2-font-style: normal;
  --headline-h2-font-weight: 700;
  --headline-h2-letter-spacing: -0.64px;
  --headline-h2-line-height: 42px;
  --headline-h3-font-family: "Mulish", sans-serif;
  --headline-h3-font-size: 26px;
  --headline-h3-font-style: normal;
  --headline-h3-font-weight: 700;
  --headline-h3-letter-spacing: -0.52px;
  --headline-h3-line-height: 32px;
  --headline-h4-font-family: "Mulish", sans-serif;
  --headline-h4-font-size: 20px;
  --headline-h4-font-style: normal;
  --headline-h4-font-weight: 700;
  --headline-h4-letter-spacing: -0.4px;
  --headline-h4-line-height: 28px;
  --hover-frame: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  --orangehard: rgba(255, 175, 0, 1);
  --orangelight: rgba(255, 246, 232, 1);
  --orangemedium: rgba(255, 204, 129, 1);
  --primaryhover: rgba(42, 116, 215, 1);
  --primarylight: rgba(224, 237, 255, 1);
  --primaryprimary: rgba(64, 142, 246, 1);
  --redhard: rgba(228, 0, 52, 1);
  --redlight: rgba(255, 237, 232, 1);
  --redmedium: rgba(255, 170, 175, 1);
  --subtitle-s2-font-family: "Mulish", sans-serif;
  --subtitle-s2-font-size: 16px;
  --subtitle-s2-font-style: normal;
  --subtitle-s2-font-weight: 600;
  --subtitle-s2-letter-spacing: -0.32px;
  --subtitle-s2-line-height: 22px;
  --subtitle-s3-font-family: "Mulish", sans-serif;
  --subtitle-s3-font-size: 14px;
  --subtitle-s3-font-style: normal;
  --subtitle-s3-font-weight: 600;
  --subtitle-s3-letter-spacing: -0.28px;
  --subtitle-s3-line-height: normal;
  --techblack: rgba(3, 16, 34, 1);
  --techgrey-border: rgba(243, 244, 246, 1);
  --techgrey-hard: rgba(100, 112, 129, 1);
  --techgrey-light: rgba(228, 231, 235, 1);
  --techgrey-medium: rgba(168, 177, 190, 1);
  --techwhite: rgba(255, 255, 255, 1);
}


main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
