.add-button {
    width: 250px ;
}

@media (max-width: 430px) {
   .add-button {
        width: 100% !important;
    }

}
