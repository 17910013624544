.breadcrumbs {
  width: 100%;
  display: flex;
  /* padding: 0px 24px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.breadcrumbs-path {
  display: flex;
  align-items: center;
}

.breadcrumbs-pathname {
  display: flex;
  align-items: center;
}


.breadcrumbs-pathname-value {
  cursor: pointer;
}

.breadcrumbs-home {
  width: 14px;
  height: 14px;
}

.breadcrumbs-home:hover {
  cursor: pointer;
}

.breadcrumbs-slash {
  padding: 0 8px;
}

.breadcrumbs-path span {
  color: var(--tech-grey-medium, #a8b1be);

  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.28px;
  
}

.breadcrumbs-title {
  color: var(--tech-black, #031022);
  font-family: 'Mulish';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.64px;
}

.breadcrumbs-back {
  display: none;
}

@media (max-width: 820px) {
  .breadcrumbs-title {
    color: var(--Tech-Black, #031022);

    font-family: Mulish;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; 
    letter-spacing: -0.52px;
  }

  .breadcrumbs-back {
    display: block !important;
  }

  .breadcrumbs-path {
    display: none;
  }

  .badge-block {
    display: none;
  }
}

@media (max-width: 430px) {

}