.balances-page {
  /* width: 1200px; */
  width: 100%;
  /* height: 100%; */
  display: inline-flex;
}

.balances-page-content {
  width: 100%;
  margin: 0px 24px 24px 24px;

  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

/* @media (max-width: 820px) {
      .balances-page-content {
        padding-bottom: 70px;
      }
    } */

.balances-page-content-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.balances-page-content-hello {
  color: var(--tech-grey-medium, #a8b1be);

  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.28px;
}

.balances-page-content-head {
  color: var(--tech-black, #031022);

  font-family: 'Mulish';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.64px;
}

.balances-page-content-body {
  display: flex;
  width: 100%;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 20px;
  background: var(--tech-white, #fff);

  /* Frame */
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.balances-page-content-body-title {
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  border-bottom: 1px solid var(--tech-grey-border, #f3f4f6);
}

.balances-page-content-body-title span {
  color: var(--tech-black, #031022);

  font-family: 'Mulish';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.4px;
}

.balances-page-content-body-text {
  display: flex;
  padding: 0px 24px 24px 24px;
  align-items: center;
  gap: 32px;
  align-self: stretch;

  color: var(--tech-black, #031022);

  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.balances-page-content-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.balances-page-content-body-qr {
  width: 153px;
  height: 153px;
  background-color: grey;
}

@media (max-width: 430px) {
  .balances-page-content-body-qr {
    display: none;
  }
  .balances-page-content {
    padding-bottom: 65px;
  }
  .balances-page-content-button .large-button {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
