.channel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  white-space: nowrap;
}

.channel-button-icon {
  display: flex;
  width: 40px;
  height: 36px;
  padding: 6px 8px;
  /* padding: 5.819px 4.066px 4.481px 3.391px; */
  justify-content: center;
  align-items: center;
}

.channel-button-icon img {
  /* align-self: flex-start; */
  width: 28.544px;
  height: 25.7px;
  flex-shrink: 0;
}

.channel-button.open .channel-button-title {
  color: #000;
  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: -0.32px;

  animation: 0.8s fadeInOpacity ease-out;
  opacity: 1;
}

.channel-button-title {
  color: #000;
  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: -0.32px;

  animation: 0.8s fadeOutOpacity ease-out;
  opacity: 0;
}

.channel-button.open .channel-button-subtext {
  color: var(--tech-grey-hard, #647081);

  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;

  animation: 0.8s fadeInOpacity ease-out;
  opacity: 1;
}

.channel-button-subtext {
  color: var(--tech-grey-hard, #647081);

  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;

  animation: 0.8s fadeOutOpacity ease-out;
  opacity: 0;
}

.channel-button:hover {
  cursor: pointer;
}

.channel-button:hover .channel-button-title {
  color: var(--primary-primary, #408ef6);

  cursor: pointer;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
