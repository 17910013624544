.balance-item {
  display: flex;
  width: calc(100% - 24px);
  padding: 12px;
  /* margin: 0 24px; */
  justify-content: space-between;
  align-items: center;

  border-radius: 16px;
  background: var(--tech-white, #fff);

  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  /* white-space: nowrap; */
  transition: box-shadow 0.3s ease;
}

.balance-item.closed {
  box-shadow: none;
}

.balance-item-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  flex-shrink: 0;

  background-color: #f3f4f6;
  border-radius: 50px;
}

.balance-item:hover .balance-item-logo svg rect {
  fill: var(--primary-primary, #408ef6);
}

.balance-item:hover .balance-item-logo svg g path {
  fill: var(--primary-primary, #408ef6);
}

.balance-item-currency {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.balance-item-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.balance-item-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.balance-item-code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

.balance-item-code-title {
  color: var(--tech-black, #031022);

  max-width: 170px;
  white-space: nowrap;
  font-family: "Mulish";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.balance-item-code-subtitle {
  color: var(--tech-grey-hard, #647081);

  font-family: "Mulish";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.balance-item-amount {
  color: var(--tech-black, #031022);

  font-family: "Mulish";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.balance-item-menu {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
}

.balance-item-menu:hover {
  border-radius: 16px;
  background: var(--tech-grey-border, #f3f4f6);

  cursor: pointer;
}

.balance-item-menu:hover path {
  stroke: var(--primary-primary, #408ef6);
}
