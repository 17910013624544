.home-page-content {
  /* height: 100%; */

  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding: 0px 24px 24px 24px;
}

@media (max-width: 820px) {
  .home-page-content {
    padding-bottom: 72px;
    gap: 24px;
  }
  .home-page-content .home-page-content-send-list {
    gap: 18px 18px;
  }
}

.home-page-content-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.home-page-content-hello {
  color: var(--tech-grey-medium, #a8b1be);

  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.28px;
}

.home-page-content-head {
  color: var(--tech-black, #031022);

  font-family: 'Mulish';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.64px;
}

.home-page-content-send-list {
  display: flex;
  align-items: stretch;

  gap: 24px 24px;
  flex-wrap: wrap;
}
