@media (max-width: 820px) {
  .tab-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 4px 12px;
    justify-content: space-between;
    align-items: center;
    /* gap: 8px; */
    border-top: 1px solid var(--tech-grey-border, #f3f4f6);
    background: #fff;
    z-index: 10000;
  }
}

@media (min-width: 821px) {
  .tab-bar {
    display: none;
  }
}
