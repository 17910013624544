.reg-form {
  width: 356px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 52px;
}

.reg-form form {
  width: 356px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

@media (max-width: 820px) {
  .reg-form {
    width: 100%;
  }

  .reg-form form {
    width: 100%;
    gap: 24px;
  }
}

.reg-form .logo {
  width: 261px;
}
