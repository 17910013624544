.auth-form {
  width: 356px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 52px;
}

.auth-form form {
  width: 356px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

@media (max-width: 1100px) {
  .auth-form {
    width: 100%;
  }

  .auth-form form {
    width: 100%;
    gap: 24px;
  }
}

.auth-form .logo {
  width: 261px;
}
