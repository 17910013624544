.history {
  max-height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  transition: max-height 0.5s ease;

  background: var(--tech-white, #ffffff);
}

.history.closed {
  max-height: 55px;
}

.history-title {
  display: flex;
  padding: 12px 0;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.history-title-arrow {
  width: 24px;
  height: 24px;
}

.history-title-arrow.expanded {
  transform: rotate(180deg);
  transition: 0.5s ease;
}

.history-title-arrow.closed {
  transform: rotate(0deg);
  transition: 0.5s ease;
}

.history-title-arrow:hover {
  cursor: pointer;
}

.history-title {
  color: var(--tech-black, #031022);

  font-family: 'Mulish';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.4px;
}

.history-list {
  display: flex;
  flex-direction: column;
  max-height: 100%;

  /* gap: 16px; */
  transition: max-height 0.5s ease;
  overflow: hidden;

  /* padding: 0px 24px; */
}

.history-list.closed {
  display: flex;

  max-height: 0px;
}
