.home-menu-item-button.open {
  width: 100%;
  display: flex;
  padding: 6px 8px;
  align-items: center;
  justify-content: space-between;

  gap: 10px;
  align-self: stretch;
  user-select: none;
  cursor: pointer;
}

.home-menu-item-button.open .home-menu-item-logo {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
}

.home-menu-item-button {
  /* display: flex; */
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
}

.home-menu-item-icon {
  display: flex;
  align-items: center;
}

.home-menu-item-icon.expanded {
  transform: rotate(180deg);
  transition: 0.8s ease;
}

.home-menu-item-icon.closed {
  transform: rotate(0deg);
  transition: 0.8s ease;
}

.home-menu-item-icon:hover {
  cursor: pointer;
}

.home-menu-item-button .home-menu-item-logo {
  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 10px;
}

.home-menu-item-button.open span {
  color: var(--tech-grey-hard, #647081);

  white-space: nowrap;

  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.32px;
  animation: 0.8s fadeInOpacity ease-out;

  opacity: 1;
}

.home-menu-item-button span {
  color: var(--tech-grey-hard, #647081);

  white-space: nowrap;

  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.32px;

  animation: 0.8s fadeOutOpacity ease-out;
  opacity: 0;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.home-menu-item-button.selected span {
  color: var(--primary-primary, #408ef6);
}

.home-menu-item-button.selected .home-menu-item-icon path {
  fill: var(--primary-primary, #408ef6);
}

/* .popup-content { */
  /* display: flex; */
  /* padding: 4px 0px; */
  /* flex-direction: column; */
  /* align-items: flex-start; */
  /* border-radius: 16px; */
  /* border: 1px solid var(--tech-border, #f3f4f6); */
  /* background: var(--White, #fefdfd); */

  /* Dropdown */
  /* box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12); */
/* } */

/* .popup-arrow {
} */

/* .popup-overlay {
  background: rgba(0, 0, 0, 0.5);
} */

/* [data-popup='tooltip'].popup-overlay {
  background: transparent;
} */
