.badge {
    align-items: center;
    border-radius: 16px;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    padding: 4px 8px;
    position: relative;
  }
  
  .badge .BADGE {
    font-family: var(--caption-c1-bold-font-family);
    font-size: var(--caption-c1-bold-font-size);
    font-style: var(--caption-c1-bold-font-style);
    font-weight: var(--caption-c1-bold-font-weight);
    letter-spacing: var(--caption-c1-bold-letter-spacing);
    line-height: var(--caption-c1-bold-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .badge.blue {
    background-color: #e7f4fd;
  }
  
  .badge.orange {
    background-color: var(--orangelight);
  }
  
  .badge.grey {
    background-color: var(--techgrey-border);
  }
  
  .badge.red {
    background-color: var(--redlight);
  }
  
  .badge.green {
    background-color: var(--greenlight);
  }
  
  .badge.blue .BADGE {
    color: var(--primaryprimary);
  }
  
  .badge.orange .BADGE {
    color: var(--orangehard);
  }
  
  .badge.grey .BADGE {
    color: var(--techgrey-hard);
  }
  
  .badge.red .BADGE {
    color: var(--redhard);
  }
  
  .badge.green .BADGE {
    color: var(--greenhard);
  }
  