.large-button.standart {
  width: 100%;

  display: inline-flex;
  padding: 14px 32px;
  align-items: center;
  justify-content: center;
  gap: 12px;

  border-radius: 16px;
  background: var(--primary-primary, #408ef6);
}

.large-button.outlined {
  width: 100%;

  display: inline-flex;
  padding: 14px 32px;
  align-items: center;  
  justify-content: center;

  gap: 12px;

  border-radius: 16px;
  border: 1px solid var(--primary-primary, #408ef6);
}

.large-button-text.standart {
  color: var(--tech-white, #fff);
  text-align: center;

  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.large-button-text.outlined {
  color: var(--primary-primary, #408ef6);
  text-align: center;

  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.large-button:hover:not(.disabled) .large-button-text {
  color: var(--tech-white, #fff);
  text-align: center;

  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.large-button:hover:not(.disabled) {
  border-radius: 16px;
  background: var(--primary-hover, #2a74d7);

  /* Drop button */
  /* box-shadow: 0px 5px 10px 0px rgba(40, 53, 193, 0.4); */
  cursor: pointer;
}

.large-button.standart.disabled {
  border-radius: 16px;
  background: var(--tech-grey-light, #e4e7eb);
}

.large-button-text.disabled {
  color: var(--tech-grey-medium, #a8b1be);
  text-align: center;

  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
