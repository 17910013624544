.radio {
    display: flex;
    align-items: flex-start;
    gap: 24px;
}

.radio .label {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.label input[type='radio'] {
    accent-color: var(--Primary-primary,#408EF6) ;
    margin: 0;
    width: 20px;
    height: 20px;
} 

.label .text-wrapper {
    color: var(--Tech-Black, #031022);
    font-family: 'Mulish';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: -0.28px;
}

@media (max-width: 830px) {
    .radio { 
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }
}