.snackbar-but-cooler {
    display: flex;
    width: 100%;
    align-items: flex-start;

    background: var(--Tech-White, #FFF);
    border-radius: 16px;

    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.snackbar-but-cooler .wrapper {
    width: 100%;
    display: flex;
    align-items: center;

}

.snackbar-but-cooler .wrapper .icon {
    display: flex;
    width: 24px;
    padding: 0px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    background: var(--Primary-Light, #E0EDFF);
}

.snackbar-but-cooler .wrapper .text-block {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.snackbar-but-cooler .wrapper .text-block .text-wrapper-1 {
    align-self: stretch;
    color: var(--Tech-Black, #031022);

    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.snackbar-but-cooler .wrapper .text-block  .text-wrapper-2 {
    align-self: stretch;
    max-width: 75%;


    color: var(--Tech-Black, #031022);

    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: -0.28px;
}

