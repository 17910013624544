.tab-bar-item {
  display: flex;
  width: 69px;
  padding: 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;

  border-radius: 16px;
}

.tab-bar-item svg {
  /* padding: 8px; */
  width: 24px;
  height: 24px;
}

.tab-bar-item.selected span {
  color: var(--primary-primary, #408ef6);
}

.tab-bar-item.selected .home-menu-item-icon path {
  fill: var(--primary-primary, #408ef6);
}

.tab-bar-item:hover .tab-bar-item-icon path {
  fill: var(--primary-primary, #408ef6);
}

.tab-bar-item-title {
  /* min-height: 36px; */

  display: flex;
  align-items: center;
}

.tab-bar-item-title span {
  color: var(--tech-grey-medium, #a8b1be);

  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;

  text-align: center;
}

.tab-bar-item:hover {
  cursor: pointer;
}
